import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import { graphql, Link } from "gatsby"

import Grid from "../components/Grid"
import SafeAreaWrapper from "../components/SafeAreaWrapper"

import Hero from "../components/Hero"
import { Simple as Card } from "../components/Card"
import Container from "../components/Container"

import Introduction from "../components/Introduction"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CompanyPage = ({
	data: { page, backgroundImage, dataImage, vesselImage, products, slackUsers },
}) => {
	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero
				title="About Hanseaticsoft"
				subtitle="New ideas emerge from new thinking"
				backgroundImage={backgroundImage}
				translucent={true}
			/>
			<div className="my-spacer-xl">
				<SafeAreaWrapper>
					<Introduction>
						Hanseaticsoft offers modern software solutions to optimize workflows in
						shipping companies. We help companies to gain new perspectives on processes
						and increase business performance.
					</Introduction>

					<Container className="my-spacer-xl flex justify-center">
						<div className="inline-grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4  gap-x-[100px] gap-y-[40px] justify-center">
							<div>
								<h1 className="text-center display1 text-pictonblue">2009</h1>
								<h3 className="text-center display3 font-normal whitespace-nowrap">
									Founded
								</h3>
							</div>
							<div>
								<h1 className="text-center display1 text-solaris">
									{slackUsers.totalCount}
								</h1>
								<h3 className="text-center display3 font-normal whitespace-nowrap">
									Team members
								</h3>
							</div>
							<div>
								<h1 className="text-center display1 text-atlantis">
									{Math.ceil(products.totalCount / 10) * 10}+
								</h1>
								<h3 className="text-center display3 font-normal whitespace-nowrap">
									Applications
								</h3>
							</div>
							<div>
								<h1 className="text-center display1 text-tealblue">5</h1>
								<h3 className="text-center display3 font-normal whitespace-nowrap">
									Office locations
								</h3>
							</div>
						</div>
					</Container>

					<Container className="my-spacer-xl">
						<Grid withContainer={false} className="my-[150px] first:mt-0 last:mb-0">
							<div className="col-start-1 col-span-12 md:col-start-2 md:col-end-12 xl:col-start-2 xl:col-span-4 flex flex-col justify-center">
								<h1 className="display1 mb-30 mt-40 xl:mt-0">Our vision</h1>
								<p>
									The world is shaped by harsh competition and cross-linked
									business processes. To consider the interests and demands of
									stakeholders, complex processes have to be simplified. Only this
									way business modells can be made fit for the future. To tap the
									full potential of businesses, information has to be analyzed in
									real time and problems have to be identified before they appear.
									Tailor-made IT solutions for each department help to operate
									transparently at all time. For us, an IT solution is perfect as
									soon as it becomes work saving and intuitive. That way it
									becomes the driving force of successful business processes.
								</p>
							</div>
							<div className="order-first xl:order-none col-start-1 col-span-12 md:col-start-2 md:col-end-12 xl:col-start-7 xl:col-span-5 flex flex-col justify-center items-center">
								<GatsbyImage
									className="max-w-[535px]"
									image={getImage(dataImage)}
									alt="Data"
									title="Data"
								/>
							</div>
						</Grid>
						{/* ---  */}
						<Grid withContainer={false} className="my-[150px] first:mt-0 last:mb-0">
							<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-12 xl:col-start-2 xl:col-span-5 flex flex-col justify-center items-center">
								<GatsbyImage
									className="max-w-[535px]"
									image={getImage(vesselImage)}
									alt="Vessel"
									title="Vessel"
								/>
							</div>
							<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-12 xl:col-start-8 xl:col-span-4 flex flex-col justify-center">
								<h1 className="display1 mb-30 mt-40 xl:mt-0">Our mission</h1>
								<p>
									Especially in times of information overflow, Hanseaticsoft
									enables shipping companies to fully overview their processes. By
									overcoming technological and organizational boundaries,
									businesses will profit from advantages within a competitive
									environment. We help our clients to focus on their tasks and
									essentials. We pay particular attention to the comprehensibility
									of our system solutions to assure perfect conditions for an
									ideal collaboration of employees. We commit ourselves to
									technical competence and profound expertise. That way we develop
									solutions that are easy to use, give pleasure and meet highest
									requirements.
								</p>
							</div>
						</Grid>
						{/* --- */}
						<Grid withContainer={false} className="my-[150px] first:mt-0 last:mb-0">
							<div className="col-start-1 col-span-12 md:col-start-2 md:col-end-12 xl:col-start-2 xl:col-span-4 flex flex-col justify-center">
								<h2 className="display2 mb-30 mt-40 xl:mt-0">
									Part of Lloyd’s Register
								</h2>
								<p>
									Since 2017, Hanseaticsoft is part of the Lloyd’s Register Group.
									We are very pleased to have found a partner who shares our
									vision of the digital future of shipping with the same passion
									as we do. The experience of LR and the agility of Hanseaticsoft
									perfectly complement each other to meet the global challenges of
									the shipping sector. Together, we accelerate the digital
									transformation of the maritime industry.
								</p>
							</div>
							<div className="order-first xl:order-none col-start-1 col-span-12 md:col-start-2 md:col-end-12 xl:col-start-7 xl:col-span-5 flex flex-col justify-center items-center">
								<svg
									className="h-[200px] md:h-[250px] lg:h-[300px] xl:h-[400px]"
									viewBox="0 0 377 432"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M234.52 243.97 341.03 375.2h-47.24L163.01 214.7h44.18c49.73 0 78.95-22.92 78.95-63.01 0-39.77-27.73-62.56-77.46-62.56h-45.54V56.85h49.22c70.54 0 112.26 35.46 112.26 94.85 0 50.72-33.96 87.55-90.1 92.27M94.5 342.73h139.57v32.39H55.77V56.88H94.5v285.85ZM376.37.75H.28v430.73h376.09V.75Z"
										fill="#00A99D"
										fillRule="nonzero"
									/>
								</svg>
							</div>
						</Grid>
					</Container>

					<div className="bg-porcelain -mb-100">
						<Container className="pt-spacer-xl">
							<h1 className="pb-spacer-lg text-center display1">
								Many years of developing cross-departmental software made us experts
								in this field
							</h1>

							<div className="flex flex-col xl:flex-row justify-stretch items-stretch space-x-0 space-y-20 xl:space-x-20 xl:space-y-0">
								{page.leaders.map(leader => (
									<Card className="w-full">
										<GatsbyImage
											className="w-full h-[280px]"
											imgClassName="w-full h-[280px] rounded-t-medium overflow-hidden"
											image={getImage(leader.photo?.localFile)}
											objectPosition="center center"
											objectFit="cover"
											alt={`${leader.photo?.alternativeText}`}
											title={`${leader.name} (${leader.title})`}
										/>
										<div className="m-30">
											<a
												href={`https://www.linkedin.com/in/${leader.linkedinUsername}/`}
												target="_blank"
												rel="noreferrer"
												className="items-center text-pictonblue hover:text-tealblue transition-color duration-300 ease-in-out"
												title={`Connect with ${leader.name} on LinkedIn`}
												alt={`Connect with ${leader.name} on LinkedIn`}>
												<h3 className="mt-spacer-sm mb-12 display3 whitespace-nowrap">
													{leader.name}
												</h3>
											</a>
											<p className="text-md">{leader.title}</p>
										</div>
									</Card>
								))}
							</div>
						</Container>
					</div>

					<div className="bg-white pt-100">
						<Grid className="mt-spacer-xl gap-y-40">
							<div className="col-span-12 lg:col-span-6">
								<Link to="/contact">
									<div className="bg-pictonblue hover:shadow-xl duration-300 transition-color pt-60 pb-80 px-60 flex flex-col items-start rounded-medium h-full">
										<p className="captionBtn text-left text-white mb-10">
											Say hello to us
										</p>
										<h2 className="display2 text-left text-white">
											Learn about our locations and contact our different
											offices
										</h2>
									</div>
								</Link>
							</div>
							<div className="col-span-12 lg:col-span-6">
								<Link to="/customers">
									<div className="bg-tealblue hover:shadow-xl duration-300 transition-color pt-60 pb-80 px-60 flex flex-col items-start rounded-medium h-full">
										<p className="captionBtn text-left text-white mb-10">
											Our Customers
										</p>
										<h2 className="display2 text-left text-white">
											Learn why so many companies trust us
										</h2>
									</div>
								</Link>
							</div>
						</Grid>
					</div>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiCompanyPage {
			seo {
				title
				description
				keywords
			}
			leaders {
				name
				linkedinUsername
				title
				photo {
					alternativeText
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(height: 300)
						}
					}
				}
			}
		}

		backgroundImage: file(relativePath: { eq: "workshop.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		dataImage: file(relativePath: { eq: "data.png" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 700)
			}
		}

		# satelliteImage: file(relativePath: { eq: "satellite.png" }) {
		# 	childImageSharp {
		# 		gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 700)
		# 	}
		# }

		vesselImage: file(relativePath: { eq: "vessel.png" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 700)
			}
		}

		slackUsers: allSlackUser(
			filter: {
				is_bot: { eq: false }
				is_email_confirmed: { eq: true }
				is_restricted: { eq: false }
				is_ultra_restricted: { eq: false }
				deleted: { eq: false }
				profile: { email: {} }
			}
		) {
			totalCount
		}

		products: allStrapiProduct {
			totalCount
		}
	}
`

export default CompanyPage
